<template>
  <div class="flex flex-col items-center w-full h-full">
    <interior-page-content-container
      nav-selection="dashboard"
      :show-back-button-top-left="false"
      :show-bottom-nav="false"
      :show-back-button-in-nav="false"
      :loading="loading"
      :modal-show="walkthroughData.showModal"
      :pulse="walkthroughData.menuItem"
    >

      <template #modal>
        <walkthrough-modal
          v-if="walkthroughData.showModal"
          :title="walkthroughData.modalData.title"
          @confirm="walkthroughActions({action: 'INCREMENT'})"
          @cancel="walkthroughActions({action: 'CANCEL'})"
          :description="walkthroughData.modalData.description"
          textCancel='Finish'
          :textConfirm='walkthroughData.modalData.textConfirm'
        />
      </template>

      <template #title>
        Dashboard
      </template>

      <template #content>

        <div class="flex flex-col flex-shrink-0 py-10 mx-10">

          <!-- Menu items -->
          <div class="`
            flex flex-row self-center justify-between flex-shrink-0
            w-full phone:hidden
          `">

            <!-- column 1 -->
            <div class="flex flex-col pr-5">
              <dashboard-menu-option
                @click="navigate('Projects')"
                icon="document"
                class="flex-shrink-0"
                :pulse="walkthroughData.menuItem === 'Projects'"
              >
                <template  #title>
                  Projects
                </template>
                <template #description>
                  You have {{projectsList.length}}
                  active {{projectsList.length === 1 ? 'project' : 'projects'}}
                </template>
              </dashboard-menu-option>

              <dashboard-menu-option
                @click="navigate('Invite')"
                icon="invite"
                class="flex-shrink-0"
                :pulse="walkthroughData.menuItem === 'Invite'"
              >
                <template v-slot:title>
                  Invite
                </template>
                <template v-slot:description>
                  Share KIV with friends
                </template>
              </dashboard-menu-option>

              <dashboard-menu-option
                @click="navigate('ToDo')"
                icon="heart"
                class="flex-shrink-0"
                :pulse="walkthroughData.menuItem === 'To Do'"
              >
                <template v-slot:title>
                  To Do
                </template>
                <template v-slot:description>
                  View All To Do Items
                </template>
              </dashboard-menu-option>

            </div>

            <!-- column 2 -->
            <div class="flex flex-col ">
              <dashboard-menu-option
                @click="navigate('Rewards')"
                icon="gift"
                class="flex-shrink-0"
              >
                <template v-slot:title>
                  Rewards
                </template>
                <template v-slot:description>
                  Gain Real Rewards
                </template>
              </dashboard-menu-option>

              <dashboard-menu-option
                @click="navigate('Assistance')"
                icon="phone"
                class="flex-shrink-0"
                :pulse="walkthroughData.menuItem === 'Assistance'"
              >
                <template v-slot:title>
                  Assistance
                </template>
                <template v-slot:description>
                  Customer Service
                </template>
              </dashboard-menu-option>

              <dashboard-menu-option
                @click="navigate('Calculators')"
                icon="calculator"
                class="flex-shrink-0"
                :pulse="walkthroughData.menuItem === 'Estimators'"
              >
                <template v-slot:title>
                  Estimators
                </template>
                <template v-slot:description>
                  Calculations Made Easy
                </template>
              </dashboard-menu-option>
            </div>

          </div>

          <!-- List of projects -->
          <div
            v-if="projectsList.length > 0"
            class="flex flex-col items-center pt-5"
          >
            <p class="mb-5 text-2xl font-bold text-content">Recently Viewed Projects</p>
            <div v-if="projectsList.length === 0" class="text-content">
              <p>
                You currently have no open KIV Projects.
                You can create one by going to the
                <span
                  class="font-bold underline cursor-pointer"
                  @click="$router.push({name:'Projects'})"
                >Projects</span>
                section!
              </p>
            </div>

            <project-component
              v-for="(project, index) in recentProjects"
              @click="navToProject(project.id)"
              :key="`project-${index}`"
              :name="project.name"
              :accessType="accessType(project)"
              :image="project.picture.url"
            />

          </div>

          <div v-if="projectsList.length === 0" class="flex flex-col items-center pt-10">

            <p class="mb-5 text-2xl font-bold text-content">Welcome to Kept In View!</p>

            <p class="mt-2 text-content">
              Once you are ready, go to the
              <span
                class="font-bold underline cursor-pointer"
                @click="$router.push({name:'Projects'})"
              >Projects</span>
              section to create your first project!
            </p>

          </div>

        </div>

      </template>
    </interior-page-content-container>
  </div>
</template>

<script>
import { mapProjectFields } from '@/store/mappers';
import {
  USER_WALKTHROUGH_DISPATCH,
  PROJECTS_ALL,
  PROJECT_ACCEPT_INVITE,
} from '@/store/actions';
import { USER_WALKTHROUGH_STATE, PROJECT_PERMISSIONS } from '@/store/getters';

import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import DashboardMenuOption from '@/components/dashboard/DashboardMenuOption.vue';
import ProjectComponent from '@/components/projects/ProjectComponent.vue';
import WalkthroughModal from '@/components/walkthrough/WalkthroughModal.vue';

export default {
  name: 'Dashboard',
  components: {
    DashboardMenuOption,
    InteriorPageContentContainer,
    ProjectComponent,
    WalkthroughModal,
  },
  data() {
    return {
      loading: true,
      iframeAllowInfo: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
    };
  },
  computed: {
    ...mapProjectFields(['projectsList']),
    recentProjects() {
      return this.projectsList.slice(0, 2);
    },
    walkthroughData() {
      const stage = this.$store.getters[USER_WALKTHROUGH_STATE];
      // Picking menu item
      let menuItem = '';
      if (stage === 1) menuItem = 'Projects';
      else if (stage === 16) menuItem = 'Invite';
      else if (stage === 19) menuItem = 'Assistance';
      else if (stage === 22) menuItem = 'To Do';
      else if (stage === 25) menuItem = 'Estimators';
      else menuItem = '';

      // picking modal data
      let modalData = {};
      if (stage === 0) {
        modalData = {
          title: 'Welcome to the KIV walkthrough!',
          description: 'Kept in View is for downsizing and safety used for elderly people. If you would like to start the walkthorugh, click the \'Go to Projects\' page!',
          textConfirm: 'Go to Projects',
        };
      } else if (stage === 15) {
        modalData = {
          title: 'Invite Friends!',
          description: 'KIV has a unique way of saying "Thank you" when you invite other users to join the KIV community. Everytime you invite a user, you are rewarded KIV Points that can be viewed in the "Rewards" tab. Take a look at ',
          textConfirm: 'View Invites',
        };
      } else if (stage === 18) {
        modalData = {
          title: 'Let us assist you!',
          description: 'Welcome to KIV assistance. You’ll find answers to the questions we get asked the most about using KIV app. Also, want to talk to a downsizing/safety specialist just, pick up the time to chat online for 30 minutes free consultation.',
          textConfirm: 'View Assistance',
        };
      } else if (stage === 21) {
        modalData = {
          title: 'Keep a birds eye view',
          description: 'Managing all of your projects and keeping an eye on what you need to do can be tricky. The global To Do tab lists all of your hearted checklist items from across all of your projects. It\'s easy and all in one place.',
          textConfirm: 'View To Dos',
        };
      } else if (stage === 24) {
        modalData = {
          title: 'KIV Estimators!',
          description: 'And finally, KIV offers you our amazing cost estimators! You can use them to estimate costs for things such as Downsizing, Monthly Costs, as well as Home Modifications',
          textConfirm: 'View Estimators',
        };
      } else if (stage === 27) {
        modalData = {
          title: 'You\'re now a KIV Veteran!',
          description: `
            Congratulations, you are now a KIV Veteran! All notifications that
            you might recieve will appear under the bell at the top right of the page. 
            We are looking forward having you in our KIV Community!
          `,
          textConfirm: '',
        };
      } else modalData = null;

      // Show modal
      const showModal = !!modalData;
      return {
        menuItem,
        modalData,
        showModal,
      };
    },
  },
  beforeRouteLeave(to, from, next) {
    this.walkthroughActions({ action: 'LEAVE', next, to });
  },
  async created() {
    this.loading = this.projectsList === [];
    this.$store.dispatch(PROJECT_ACCEPT_INVITE)
      .then((data) => {
        if (data && data.project_id) {
          this.$router.push({
            name: 'Project',
            params: {
              project_id: data.project_id,
              tab: 'Declutter',
            },
            query: {
              first_visit_to_project: true,
            },
          });
        }
      });

    this.$store.dispatch(PROJECTS_ALL)
      .finally(() => { this.loading = false; });
  },
  methods: {
    walkthroughActions({ action, next, to }) {
      if (action === 'INCREMENT') this.$store.dispatch(USER_WALKTHROUGH_DISPATCH);
      else if (action === 'CANCEL') this.$store.dispatch(USER_WALKTHROUGH_DISPATCH, 100);
      else if (action === 'LEAVE') {
        const projectsWalkthrough = to.name === 'Projects' && this.walkthroughData.menuItem === 'Projects';
        const inviteWalkthrough = to.name === 'Invite' && this.walkthroughData.menuItem === 'Invite';
        const assistanceWalkthrough = to.name === 'Assistance' && this.walkthroughData.menuItem === 'Assistance';
        const toDoWalkthrough = to.name === 'ToDo' && this.walkthroughData.menuItem === 'To Do';
        const calculatorWalkthrough = to.name === 'Calculators' && this.walkthroughData.menuItem === 'Estimators';
        if (projectsWalkthrough || inviteWalkthrough
          || assistanceWalkthrough || toDoWalkthrough || calculatorWalkthrough) {
          this.$store.dispatch(USER_WALKTHROUGH_DISPATCH);
        } else if (this.$store.getters[USER_WALKTHROUGH_STATE] < 100) {
          this.$store.dispatch(USER_WALKTHROUGH_DISPATCH, 100);
        }
        next();
      } else console.error('Invalid walkthrough action');
    },
    menuClick(page) {
      this.$router.push(page);
    },
    navigate(routeName) {
      if (this.$route.name !== routeName) {
        this.$router.push({ name: routeName });
      }
    },
    navToProject(projectId) {
      this.$router.push({
        name: 'Project',
        params: {
          project_id: projectId,
          tab: 'Declutter',
        },
      });
    },
    accessType(project) {
      const type = this.$store.getters[PROJECT_PERMISSIONS](project).split('');
      return type.splice(0, 1)[0].toUpperCase() + type.join('');
    },
    blockProp(event) {
      event.stopPropagation();
    },
  },
};
</script>
