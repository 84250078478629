<template>
  <div
    class="flex flex-col items-start flex-shrink-0 h-28"

  >
    <p
      @click="$emit('click')"
      class="`
        flex flex-row text-lg font-bold cursor-pointer text-primary
        bg-backfill rounded-lg px-2 py-1
        dark:text-secondary
        border-2 border-transparent w-full
      `"
      :class="{'animate-pulse border-2 rounded-md border-yellow-300':pulse}"
    >
      <span class="mr-2">
        <document   v-if="icon === 'document'"    />
        <gift       v-if="icon === 'gift'"        />
        <calculator v-if="icon === 'calculator'"  />
        <heart      v-if="icon === 'heart'"       />
        <phone      v-if="icon === 'phone'"       />
        <invite     v-if="icon === 'invite'"      />
      </span>
      <slot name="title" />
    </p>

    <p class="flex font-medium text-left text-content">
      <slot name="description" />
    </p>

  </div>
</template>

<script>
import Document from 'vue-material-design-icons/FileDocumentOutline.vue';
import Gift from 'vue-material-design-icons/Gift.vue';
import Calculator from 'vue-material-design-icons/Calculator.vue';
import Heart from 'vue-material-design-icons/Heart.vue';
import Phone from 'vue-material-design-icons/CellphoneInformation.vue';
import Invite from 'vue-material-design-icons/AccountGroupOutline.vue';

export default {
  name: 'DashboardMenuOption',
  components: {
    Document,
    Gift,
    Calculator,
    Heart,
    Phone,
    Invite,
  },
  props: {
    icon: {
      type: String,
      required: true,
      validation: (val) => [
        'document', 'gift', 'phone', 'calculator', 'heart', 'invite',
      ].includes(val),
    },
    pulse: {
      type: Boolean,
      required: false,
    },
  },
};
</script>
